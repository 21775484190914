// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scoreboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-height: 80vh;
    overflow-y: auto;
}

.one-player-score {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-collapse: collapse;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.scoreboard-score {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    font-size: 1.3em;
}

.scoreboard-team {
    align-items: center;
    display: flex;
    justify-content: center;
    border-left: 1px solid black;
    padding: 10px;
    background: rgb(228, 228, 228);
    flex-grow: 1;
}


.scoreboard-title {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    font-size: 1.3em;
    font-weight: bold;
    border: 1px solid #d9d9d9;
    background: white;
}

.self-indicator {
    /* font-size: 1.7em; */
    color: #1890ff;
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/scoreboard/Scoreboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;IAC3B,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,8BAA8B;IAC9B,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,4BAA4B;IAC5B,aAAa;IACb,8BAA8B;IAC9B,YAAY;AAChB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".scoreboard {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    max-height: 80vh;\n    overflow-y: auto;\n}\n\n.one-player-score {\n    border-top: 1px solid black;\n    border-bottom: 1px solid black;\n    border-collapse: collapse;\n    display: flex;\n    flex-direction: row;\n    margin-bottom: 5px;\n}\n\n.scoreboard-score {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    padding: 10px;\n    font-weight: bold;\n    font-size: 1.3em;\n}\n\n.scoreboard-team {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    border-left: 1px solid black;\n    padding: 10px;\n    background: rgb(228, 228, 228);\n    flex-grow: 1;\n}\n\n\n.scoreboard-title {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n    padding: 10px;\n    font-size: 1.3em;\n    font-weight: bold;\n    border: 1px solid #d9d9d9;\n    background: white;\n}\n\n.self-indicator {\n    /* font-size: 1.7em; */\n    color: #1890ff;\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
