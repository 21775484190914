// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-lobby {
    padding: 10px;
    display: flex;
    min-height: 30vh;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: flex-start;
}

.start-button {
    margin-top: 10px;
    margin-left: 100px;
}

.invite {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px
}

.invite-button {
    margin-top: 15px;
    align-self: flex-end;
}

.invite-link {
    width: 300px;
}

.save-container {
    display: flex;
    justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/lobby/Lobby.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB;AACJ;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".game-lobby {\n    padding: 10px;\n    display: flex;\n    min-height: 30vh;\n    flex-direction: row;\n    flex-wrap: wrap;\n    flex-grow: 1;\n    align-items: flex-start;\n}\n\n.start-button {\n    margin-top: 10px;\n    margin-left: 100px;\n}\n\n.invite {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding-top: 10px\n}\n\n.invite-button {\n    margin-top: 15px;\n    align-self: flex-end;\n}\n\n.invite-link {\n    width: 300px;\n}\n\n.save-container {\n    display: flex;\n    justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
