// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-list {
	display: flex;
	flex-direction: row;
    flex-wrap: wrap;
	justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    width: 100%;
    padding: 5px;
    flex: 1 1 auto;
}

.ql_and_filter {
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px;
    flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./src/question/QuestionList.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;IAChB,eAAe;CAClB,2BAA2B;IACxB,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;CACC,aAAa;IACV,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;IACX,cAAc;AAClB","sourcesContent":[".question-list {\n\tdisplay: flex;\n\tflex-direction: row;\n    flex-wrap: wrap;\n\tjustify-content: flex-start;\n    align-items: center;\n    align-content: flex-start;\n    width: 100%;\n    padding: 5px;\n    flex: 1 1 auto;\n}\n\n.ql_and_filter {\n\tdisplay: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    margin: 5px;\n    flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
