// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round-and-index {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: stretch;
    cursor: pointer;
}

.round-in-game {
    display: flex;
    border-radius: 5px;
    /* padding: 5px; */
    border: 1px solid #d9d9d9;
    font-size: .9em;
    text-align: left;
    cursor: pointer;
    margin: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.round-title {
    /* padding-bottom: 5px; */
    margin: 5px;
}

.empty-round {
    color: #707070;
    margin: 5px;
    text-align: center;
    flex-grow: 1;
    /* display: flex; */
}

.removable-round {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #d9d9d9;
    padding: 5px;
    margin: 10px;
    margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/game/OpenGame.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".round-and-index {\n    display: flex;\n    flex-direction: column;\n    align-self: stretch;\n    align-items: stretch;\n    cursor: pointer;\n}\n\n.round-in-game {\n    display: flex;\n    border-radius: 5px;\n    /* padding: 5px; */\n    border: 1px solid #d9d9d9;\n    font-size: .9em;\n    text-align: left;\n    cursor: pointer;\n    margin: 5px;\n    padding: 5px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.round-title {\n    /* padding-bottom: 5px; */\n    margin: 5px;\n}\n\n.empty-round {\n    color: #707070;\n    margin: 5px;\n    text-align: center;\n    flex-grow: 1;\n    /* display: flex; */\n}\n\n.removable-round {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    border: 1px solid #d9d9d9;\n    padding: 5px;\n    margin: 10px;\n    margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
