// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepage {
    /* padding: 30px; */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    max-width: calc(100vw);
    overflow-x: hidden;
}

.new-game-button {
    padding: 20px;
    cursor: pointer;
}

.game-name {
    cursor: pointer;
    border: 1px solid black;
    padding: 5px;
    margin: 5px;
}

.new-game {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 25px;

}
`, "",{"version":3,"sources":["webpack://./src/homepage/Homepage.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,YAAY;IACZ,aAAa;;AAEjB","sourcesContent":[".homepage {\n    /* padding: 30px; */\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    align-items: flex-start;\n    max-width: calc(100vw);\n    overflow-x: hidden;\n}\n\n.new-game-button {\n    padding: 20px;\n    cursor: pointer;\n}\n\n.game-name {\n    cursor: pointer;\n    border: 1px solid black;\n    padding: 5px;\n    margin: 5px;\n}\n\n.new-game {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    flex-grow: 1;\n    padding: 25px;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
