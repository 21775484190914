// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-and-toolbar {
    display: flex;
    flex-direction: row;
}
.editor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    /* flex-grow: 1; */
}

.flex-grow {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
}

.filter {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
    padding: 10px;
    align-items: center;

}

.filter-subitem {
    display: flex;
    margin-right: 10px;
}

.filter_holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    align-items: center;
    padding: 5px;
    align-self: flex-start;
}

`, "",{"version":3,"sources":["webpack://./src/editor/Editor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;IAC3B,aAAa;IACb,mBAAmB;;AAEvB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;AAC1B","sourcesContent":[".editor-and-toolbar {\n    display: flex;\n    flex-direction: row;\n}\n.editor {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    height: 100%;\n    /* flex-grow: 1; */\n}\n\n.flex-grow {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    align-self: stretch;\n}\n\n.filter {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n    padding: 10px;\n    align-items: center;\n\n}\n\n.filter-subitem {\n    display: flex;\n    margin-right: 10px;\n}\n\n.filter_holder {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    border: 1px solid #d9d9d9;\n    border-radius: 2px;\n    align-items: center;\n    padding: 5px;\n    align-self: flex-start;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
