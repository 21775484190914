// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self: flex-start;
    align-items: center;
    padding: 25px;
    overflow-y: auto;
}

.round-and-open-question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    overflow-y: clip;
}
`, "",{"version":3,"sources":["webpack://./src/round/RoundList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,2BAA2B;IAC3B,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".round_list {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n    align-self: flex-start;\n    align-items: center;\n    padding: 25px;\n    overflow-y: auto;\n}\n\n.round-and-open-question {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    flex-grow: 1;\n    overflow-y: clip;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
