// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#root, body {
  display: flex;
  /* height: 100vh; */
  margin: 0px;
}

.logo {
  /* height: 32px; */
  float: left;
  background: rgba(80, 80, 80);
  margin: 16px;
  display: flex;
  align-items: center;
  font-style: italic;

  color: #777777;
}

.logo-min {
  justify-content: center;
}

.icon {
  margin: 5px 10px 5px 10px;
  width: 25px;
  height: 25px;
}

.left-aligned-flexrow {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
}

.height-trick {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;
}


body {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;
}

nohover:hover {
  background-color: yellow;
}`, "",{"version":3,"sources":["webpack://./src/common/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,4BAA4B;EAC5B,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;;EAElB,cAAc;AAChB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa,EAAE,gEAAgE;EAC/E,kCAAkC;EAClC,kBAAkB;AACpB;;;AAGA;EACE,aAAa,EAAE,gEAAgE;EAC/E,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".App {\n  text-align: center;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n#root, body {\n  display: flex;\n  /* height: 100vh; */\n  margin: 0px;\n}\n\n.logo {\n  /* height: 32px; */\n  float: left;\n  background: rgba(80, 80, 80);\n  margin: 16px;\n  display: flex;\n  align-items: center;\n  font-style: italic;\n\n  color: #777777;\n}\n\n.logo-min {\n  justify-content: center;\n}\n\n.icon {\n  margin: 5px 10px 5px 10px;\n  width: 25px;\n  height: 25px;\n}\n\n.left-aligned-flexrow {\n  display: flex;\n  flex-direction: row;\n  align-self: flex-start;\n  align-items: center;\n}\n\n.height-trick {\n  height: 100vh; /* Fallback for browsers that do not support Custom Properties */\n  height: calc(var(--vh, 1vh) * 100);\n  overflow-y: hidden;\n}\n\n\nbody {\n  height: 100vh; /* Fallback for browsers that do not support Custom Properties */\n  height: calc(var(--vh, 1vh) * 100);\n  overflow-y: hidden;\n}\n\nnohover:hover {\n  background-color: yellow;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
