// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wager {
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    align-items: stretch;
    justify-content: center;
    background: #d4d4d4;
    font-weight: bold;
}

.plus-or-minus {
    cursor: pointer;
    padding: 5px;
    -webkit-user-select: none;
            user-select: none;
}

.decr { border-radius: 5px 0px 0px 5px; }
.incr { border-radius: 0px 5px 5px 0px; }

.plus-or-minus:hover {
    background: #bfbfbf;
}

.wager-text {
    font-size: 1.2em;
    text-align: center;
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 5px;
    padding-right: 5px;
}

.disabled_action {
    color: darkgrey;
    cursor: default;
}

.disabled_action:hover {
    background: #d4d4d4;;
}
`, "",{"version":3,"sources":["webpack://./src/round/Wager.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,oBAAoB;IACpB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA,QAAQ,8BAA8B,EAAE;AACxC,QAAQ,8BAA8B,EAAE;;AAExC;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".wager {\n    display: flex;\n    flex-direction: row;\n    margin-right: 5px;\n    margin-bottom: 5px;\n    border: 1px solid black;\n    border-radius: 5px;\n    text-align: center;\n    align-items: stretch;\n    justify-content: center;\n    background: #d4d4d4;\n    font-weight: bold;\n}\n\n.plus-or-minus {\n    cursor: pointer;\n    padding: 5px;\n    user-select: none;\n}\n\n.decr { border-radius: 5px 0px 0px 5px; }\n.incr { border-radius: 0px 5px 5px 0px; }\n\n.plus-or-minus:hover {\n    background: #bfbfbf;\n}\n\n.wager-text {\n    font-size: 1.2em;\n    text-align: center;\n    display: flex;\n    align-items: center;\n    flex-grow: 1;\n    padding-left: 5px;\n    padding-right: 5px;\n}\n\n.disabled_action {\n    color: darkgrey;\n    cursor: default;\n}\n\n.disabled_action:hover {\n    background: #d4d4d4;;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
