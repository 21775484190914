// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.open-scoreboard-button {
    position: absolute;
    right: 10;
    top: 10;
}

.scorer-icon {
    padding-left: .7em;
    padding-right: .7em;
    margin: 5px;
    font-size: 1.3em;
    cursor: pointer;
    border-radius: 2px;
}

.score-and-override {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.answer-scorer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    padding-bottom: 10px;
}

.player-scorer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-top: 20px;
}

.answer-and-scorer {
    border: 1px solid black;
    margin: 5px;
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    min-width: 100px;
}

.selected-correctness {
    border: 2px solid #d9d9d9;
}`, "",{"version":3,"sources":["webpack://./src/admin-scorer/Scorer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;AACX;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".open-scoreboard-button {\n    position: absolute;\n    right: 10;\n    top: 10;\n}\n\n.scorer-icon {\n    padding-left: .7em;\n    padding-right: .7em;\n    margin: 5px;\n    font-size: 1.3em;\n    cursor: pointer;\n    border-radius: 2px;\n}\n\n.score-and-override {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.answer-scorer {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    font-size: 1.5em;\n    padding-bottom: 10px;\n}\n\n.player-scorer {\n    display: flex;\n    flex-direction: row;\n    align-items: stretch;\n    margin-top: 20px;\n}\n\n.answer-and-scorer {\n    border: 1px solid black;\n    margin: 5px;\n    /* padding: 10px; */\n    display: flex;\n    flex-direction: column;\n    min-width: 100px;\n}\n\n.selected-correctness {\n    border: 2px solid #d9d9d9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
