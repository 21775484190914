// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer-question {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px;
    border: 1px solid black;
    width: 500px;
}

.answer-box {
    padding: 10px;
    margin: 15px;
}

.answer-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 15px;
}

.wager-count {
    position: absolute;
    padding: 3px;
    /* left: 100% - calc(100%); */
    top: 0px;
    right: 0px;
    color: rgb(99, 99, 99);

    font-size: .6em;
    font-weight: normal;
    /* transform: translate(-100%, 100%); */
}
`, "",{"version":3,"sources":["webpack://./src/answer/AnswerQuestion.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,YAAY;IACZ,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,QAAQ;IACR,UAAU;IACV,sBAAsB;;IAEtB,eAAe;IACf,mBAAmB;IACnB,uCAAuC;AAC3C","sourcesContent":[".answer-question {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin: 15px;\n    border: 1px solid black;\n    width: 500px;\n}\n\n.answer-box {\n    padding: 10px;\n    margin: 15px;\n}\n\n.answer-footer {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: flex-end;\n    margin-top: 15px;\n}\n\n.wager-count {\n    position: absolute;\n    padding: 3px;\n    /* left: 100% - calc(100%); */\n    top: 0px;\n    right: 0px;\n    color: rgb(99, 99, 99);\n\n    font-size: .6em;\n    font-weight: normal;\n    /* transform: translate(-100%, 100%); */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
