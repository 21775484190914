// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game {
    background: #d1d1d1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    margin: 10px;
    padding: 5px;
    border: 1px solid #9c9c9c;
    border-radius: 5px;
    word-break: break-all;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/game/Game.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":[".game {\n    background: #d1d1d1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100px;\n    margin: 10px;\n    padding: 5px;\n    border: 1px solid #9c9c9c;\n    border-radius: 5px;\n    word-break: break-all;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
